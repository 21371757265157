.project-pop {
    margin-top: 1.5rem;
    justify-content: center;
    justify-items: center;
    text-align: center;
    
    
}
.project-pop h1{
    justify-content: center;
    justify-items: center;
    text-align: center;
}

.project-pop p{
    text-align: justify;
}
.project-pop h1,
.project-pop p {
    margin-top: 1rem;
  color: black; 
}

.project-pop ul {
    list-style: none;
    padding: 0;
  }
  
  .project-pop li {
    margin: 0.5rem 0;
    text-align: justify;

    text-decoration: underline;
  }
  
  .img-step {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .img-step img {
    max-width: calc(100% / 2); /* Divide the container width equally among the three images */
    height: auto;
    margin: 5px;
  }
  .img-step-principal {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  
  .img-step-principal img {
    max-width: 80%;
    height: auto;
    margin: 5px;
  }