.skill {
    margin-bottom: 20px;
    max-width: 500px;
    color: white;
  }
  
  .skill-name {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .skill-bar {
    background-color: #f0f0f0;
    height: 20px;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .skill-level {
    height: 100%;
  background-color: #0069D9;
  transition: width 0.5s ease;
  width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  }
  
  @media screen and (max-width: 768px) {
    .skill-bar {
      height: 15px;
    }
  }
  