.title_about {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(23, 24, 24);
  text-transform: uppercase;
}

.title_about h1 {
  font-size: 2.5rem;
}

.cv {
  font-family: Arial, sans-serif;
  background: black;
}

.cv-margin {
  margin-top: 2rem;
  margin-left: 5rem;
}

.cv-section {
  margin-bottom: 2rem;
  
}

.cv-section h3 {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.cv-section h2 {
  font-size: 2rem;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.cv-section ul {
  list-style-type: none;
  padding-left: 0;
}

.cv-section li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  color: #fff;
}

.cv-date {
  width: 120px;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
}

.cv-content {
  margin-left: 1.5rem;
}

.cv-content h3 {
  font-size: 1.5rem;
  color: #fff;
  margin: 0;
}

.cv-content p {
  color: #fff;
  margin: 0;
}

.cv-section:last-child {
  margin-bottom: 0;
}

.toggle-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  color: #fafafa;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-button:hover {
  background-color: rgba(67, 206, 57, 0.479);
}

.toggle-button.expanded .button-content {
  content: '-';
}

.toggle-button .button-content {
  content: '+';
}

.firstC {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  margin: 1rem 15rem;
}

.cv-frame {
  margin: 0 10rem;
}

.cv-cloud {
  margin: 0 8rem;
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .firstC {
    margin: 1rem 10rem;
  }

  .cv-frame {
    margin: 0 8rem;
  }

  .cv-cloud {
    margin: 0 6rem;
  }
}

@media (max-width: 768px) {
  .firstC {
    margin: 1rem 5rem;
  }

  .cv-frame {
    margin: 0 4rem;
  }

  .cv-cloud {
    margin: 0 3rem;
  }
}

@media (max-width: 480px) {
  .firstC {
    margin: 0rem 0rem;
  }

  .cv-frame {
    margin: 0 0rem;
  }

  .cv-cloud {
    margin: 0 0rem;
  }
}
