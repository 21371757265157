.work-container {
    background-color: rgba(0, 0, 0, 0.729);
    /* background: linear-gradient(to bottom, #050f35, #0c460d);*/
  }
  
  .project-heading {
    text-align: center;
    padding: 4rem 0 2rem 0;
  }
  
  .project-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 40px;
    padding-bottom: 5rem;
  }
  
  .project-card {
    background: black;
    padding: 1.2rem 1rem;
  }
  
  .project-card img {
    width: 100%;
  }
  
  .project-title {
    color: white;
    padding: 1rem 0;
  }
  
  .pro-details p {
    padding-bottom: 1rem;
    font-size: 1.1rem;
    text-align: justify;
  }
  
  .pro-btns {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0rem;
  }
  
  .pro-btns .btn {
    padding: 0.5rem 1.7rem;
  }
  
  @media screen and (max-width: 740px) {
    .project-container {
      max-width: 90%;
      margin: auto;
      grid-template-columns: 1fr;
    }
  }
  
  .close-btn {
    display: block;
    margin: 0 auto;
    padding: 12px 32px;
    font-size: 1rem;
    text-transform: uppercase;
    background-color: rgba(162, 155, 155, 0.789);
    color: #000000;
    border: none;
    border-radius: 12px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .close-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ea3434;
  }
  
  .close-btn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(145, 126, 0, 0.5); /* Modify color if desired */
  }
  