.contact-title{
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    margin: auto;
   
}
label{
    color: #fff;
    margin-bottom: 0rem;
}
form{
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
    margin: auto;
    max-width: 600px;
}

form input, form textarea{
     margin-bottom: 1rem;
  padding: 6px 10px; /* Adjust the padding as desired */
  font-size: 0.8rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(245, 239, 239, 0.848);
  color: #f4f4f4;
}
form textarea{
    margin-bottom: 1rem;
    padding: 10px 18px;
    font-size: 0.8rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(245, 239, 239, 0.848);
    color: #f4f4f4;
    height: 70px; 
}

.error-message{

    color: red;
}