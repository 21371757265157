@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
}

body {
  background: black;
  /* background: linear-gradient(to bottom, #000000, #050f35); */
}

h1,
h4,
p,
a {
  color: white;
  text-decoration: none;
}

ul {
  list-style: none;
}

.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background-color: black;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.btn-light {
  background-color: #5dbae4;
  color: #fff;
}

.btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(145, 126, 0, 0.5); /* Modify color if desired */
}