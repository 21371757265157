
.hero-img {
    width: 100%;
    height: 10vh;
    
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
}


.hero-img::before {
    content: "";
  background: black;
  background-size: cover;
  background-position: center top;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero-img h1{
    font-size: 2.4rem;
}
.hero-img h3{
    margin-top: rem;
    font-size: 1.4rem;
    color: #fff;
}


.hero-img p{
    font-size: 1.4rem;
    text-align: center;
}

.heading{
    height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 640px){
    .hero-img h1{
        font-size: 2rem;
    }
    
}