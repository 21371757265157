.slider-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Set the container height to 100% of the viewport height */
  overflow: hidden;
}

.slider {
  margin-top: 4rem;
  display: flex;

  max-width: 1500px;
  
  transition: transform 0.5s ease-in-out;
  
}

.slider img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.7;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

h1 {
  color: white;
}

.btn-content {
  position: absolute;
  top: 50%;
  right: 20px; /* Adjust the value to move the button to the desired position */
  transform: translateY(-50%);
}

.btn-content-left {
  position: absolute;
  top: 50%;
  left: 10px; /* Adjust the value to move the button to the desired position */
  transform: translateY(-50%);
}

.icon-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: auto;
}

.icon-button .btn-icon {
  font-size: 48px;
  color: white;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .slider img {
    opacity: 1; /* Adjust the opacity for screens smaller than 768px */
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .slider img {
    opacity: 0.5; /* Adjust the opacity for screens between 768px and 1023px */
  }
}

@media (min-width: 1024px) {
  .slider img {
    opacity: 0.7; /* Adjust the opacity for screens larger than 1023px */
  }
}